<template>
  <div class="users">
    <h4>
      List of users
    </h4>
    <p-button
      @click="updateUsers()"
      class="p-button-sm"
    >
      Update users list
    </p-button>

    <Loading v-if="isLoading"/>
    <div v-else>
      <div class="p-mt-4">
        <div>show: {{ result.start }}-{{ result.end }}</div>
        <div>total: {{ total }}</div>
      </div>
      <div>
        <InputText
          v-model="searchEmail"
          type="email"
          placeholder="Search email"
          class="p-mt-2 p-mb-2"
        />
        <ToggleButton
          v-model="sortDesc"
          onLabel="Early is first"
          offLabel="Older is first"
          class="p-mt-2 p-mb-2 p-ml-2"
        />
      </div>
      <p-paginator
        v-model:first="offset"
        v-model:rows="rows"
        :rows="rows"
        :totalRecords="total"
        :rowsPerPageOptions="[20,50,100]"
      />
      <users-list
        :list="list"
        @openUser="openUser"
      />
      <p-paginator
        v-model:first="offset"
        v-model:rows="rows"
        :rows="rows"
        :totalRecords="total"
        :rowsPerPageOptions="[20,50,100]"
      />
    </div>
  </div>
</template>

<script>
/*
{ "_id": "6096f268b0ac624aa319998c",
 "products": { "current": [], "history": [] },
  "account": { "confirmedEmail": true, "availableSessions": 2, "credentials": [ "ADMIN_R2D2" ],
   "created": "2021-05-08T20:19:52.196Z" },
    "email": "andreisoroka@furyferret.com" }
    ]
 */
import { mapActions, mapState } from 'vuex';
import { debounce } from 'lodash';

import { format } from 'fecha';
import Loading from '@/components/Loading.vue';
import UsersList from '@/views/pages/users/UsersList.vue';
import PPaginator from 'primevue/paginator';
import InputText from 'primevue/inputtext';
import ToggleButton from 'primevue/togglebutton';

export default {
  name: 'Users',
  components: {
    UsersList,
    PPaginator,
    InputText,
    ToggleButton,
    Loading,
  },
  data() {
    return {
      sortDesc: false,
      offset: 0,
      searchEmail: '',
      rows: 20,
    };
  },
  created() {
    this.updateUsers();
  },
  computed: {
    ...mapState({
      list: (state) => state.users.userList.map((user) => ({
        // eslint-disable-next-line no-underscore-dangle
        id: user._id,
        account: {
          confirmedEmail: user.account.confirmedEmail,
          credentials: user.account.credentials,
        },
        products: user.products,
        email: user.email,
        created: format(new Date(user.account.created), 'YYYY-MM-DD'),
      })),
      total: (state) => state.users.total,
      result: (state) => ({
        start: state.users.current?.offset,
        end: Math.min(
          state.users.current?.offset + state.users.current?.limit,
          state.users.total,
        ),
      }),
      isLoading: (state) => state.users.loading,
    }),
  },
  methods: {
    updateUsersLazy: debounce(function updateUsers() {
      this.updateUsers();
    }, 1),
    updateUsers() {
      this.getUsers({
        limit: this.rows,
        offset: this.offset,
        sortAccountCreateOrder: this.sortDesc ? 1 : -1,
        searchEmail: this.searchEmail,
      })
        .then((isSuccess) => {
          if (!isSuccess) {
            alert('Error. Check console');
          }
        });
    },
    openUser(id) {
      this.$router.push({
        name: 'EditUser',
        params: { id },
      });
    },
    ...mapActions({
      getUsers: 'users/getUsers',
    }),
  },
  watch: {
    sortDesc() {
      this.updateUsersLazy();
    },
    offset() {
      this.updateUsersLazy();
    },
    rows() {
      this.updateUsersLazy();
    },
    searchEmail: debounce(function searchEmailUpdate() {
      this.updateUsers();
    }, 1000),
  },
};
</script>

<style lang="scss" scoped>

</style>

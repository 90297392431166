<template>
  <div class="loading">
    <ProgressSpinner class="p-m-4"/>
  </div>
</template>

<script>
import ProgressSpinner from 'primevue/progressspinner';

export default {
  name: 'Loading',
  components: {
    ProgressSpinner,
  },
};
</script>

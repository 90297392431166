<template>
  <Card class="p-m-4">
    <template #title>
      Add product
    </template>
    <template #content>
      <div class="p-mb-4">
        <div class="p-mb-1">Product</div>
        <Dropdown
          v-model="product"
          :options="products"
          optionLabel="text"
        />
      </div>
      <div class="p-mb-4">
        <div class="p-mb-1">Days</div>
        <InputText
          v-model.number="days"
          type="number"
          placeholder="Days"
        />
      </div>
      <div>
        <Button
          label="Add"
          @click="handleAddProduct"
        />
      </div>
    </template>
  </Card>
</template>

<script>
import Card from 'primevue/card';
import Dropdown from 'primevue/dropdown';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';

const PRODUCTS = [
  {
    value: 'PREMIUM',
    text: 'Premium user',
  },
  {
    value: 'BETA',
    text: 'Beta user',
  },
  {
    value: 'USER_BETA',
    text: 'Beta user (old)',
  },
  {
    value: 'Alpha',
    text: 'Alpha user',
  },
];
export default {
  name: 'AddProduct',
  components: {
    Card,
    Dropdown,
    InputText,
    Button,
  },
  data() {
    return {
      products: PRODUCTS,
      product: null,
      days: 30,
    };
  },
  methods: {
    handleAddProduct() {
      if (!this.product?.value) {
        return;
      }

      this.$emit('submit', {
        name: this.product.value,
        days: this.days,
      });
    },
  },
};
</script>

<template>
  <Loading v-if="isLoading"/>
  <div v-else>
    <user :user="user"/>
    <br/>
    <add-product v-if="isAllowProducts" @submit="handleAddProduct"/>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import Loading from '@/components/Loading.vue';
import AddProduct from '@/components/AddProduct.vue';
import User from '@/components/User.vue';

export default {
  name: 'EditUser',
  components: {
    User,
    AddProduct,
    Loading,
  },
  data() {
    return {};
  },
  created() {
    this.getUser(this.$route.params.id);
  },
  computed: {
    ...mapState({
      user: (state) => state.users.user,
      isLoading: (state) => state.users.loading,
    }),
    ...mapGetters(['isAllowProducts']),
  },
  methods: {
    handleAddProduct({ name, days }) {
      this.addProduct({
        id: this.$route.params.id,
        name,
        days,
      })
        .then((isSuccess) => {
          if (isSuccess) {
            this.getUser(this.$route.params.id);
          } else {
            alert('Error. Check console');
          }
        });
    },
    ...mapActions({
      getUser: 'users/getUser',
      addProduct: 'users/addProduct',
    }),
  },
};
</script>

<template>
  <p-data-table :value="list">
    <p-column field="id" headerStyle="width: 3rem">
      <template #body="{data: {id}}">
        <span @click="openUser(id)">open</span>
      </template>
    </p-column>
    <p-column field="email" header="Email"></p-column>
    <p-column field="account" header="Account">
      <template #body="{data: {account}}">
        <p-badge
          v-if="account.confirmedEmail"
          value="Email confirmed"
          severity="success"
          class="p-m-1"
        />
        <p-badge
          v-for="credential in account.credentials"
          :key="credential"
          :value="credential"
          severity="info"
          class="p-m-1"
        />
        <p-badge
          v-if="!account.credentials.length"
          value="User"
          class="p-m-1"
        />
      </template>
    </p-column>
    <p-column field="products" header="Products">
      <template #body="{data: { products }}">
        <p-badge
          v-for="product in products.current"
          :key="product.name"
          :value="product.name"
          severity="info"
          class="p-m-1"
        />
      </template>
    </p-column>
    <p-column field="created" header="Created"></p-column>
  </p-data-table>
</template>

<script>
import PDataTable from 'primevue/datatable';
import PColumn from 'primevue/column';
import PBadge from 'primevue/badge';

export default {
  name: 'UsersList',
  props: {
    list: {
      type: Array,
      require: true,
    },
    sortDesc: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    PDataTable,
    PColumn,
    PBadge,
  },
  data() {
    return {};
  },
  methods: {
    openUser(id) {
      this.$emit('openUser', id);
    },
  },
};
</script>

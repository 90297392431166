<template>
  <Card class="p-m-4">
    <template #title>
      User info
    </template>
    <template #content>
      <div>Id: {{ user._id }}</div>
      <div>Email: {{ user.email }}</div>
      <div>Registration: {{ formatedDate }}</div>
      <div>Current Products:
        <div>
          <div
            v-for="item in currentProducts"
            :key="item.name"
            class="p-ml-4 p-mb-2 p-mt-2"
          >
            <Badge
              :value="item.name"
              severity="info"
            />
            <span
              class="timestamp"
              :class="{'timestamp__danger': item.expired}">
            ({{ item.end }})
          </span>
          </div>
        </div>
      </div>
      <div>History Products:
        <div>
          <div
            v-for="(item, i) in user.products.history"
            :key="`${item.timestamp}-${i}`"
            class="p-ml-4 p-mb-2 p-mt-2"
          >
            <Badge
              :value="item.name"
              severity="info"
            />
            {{ item.days }} days
            <span class="timestamp">({{ item.timestamp }})</span>
          </div>
        </div>
      </div>
      <div>Credentials Products:
        <span v-for="(item, index) in user.account.credentials" :key="index"> {{ item }}</span>
      </div>
      <div>Confirmed Email: {{ user.account.confirmedEmail }}</div>
      <div>Available Sessions: {{ user.account.availableSessions }}</div>
    </template>
  </Card>
</template>

<script>
import { format } from 'fecha';
import Card from 'primevue/card';
import Badge from 'primevue/badge';

export default {
  name: 'User',
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  components: {
    Card,
    Badge,
  },
  computed: {
    formatedDate() {
      return format(new Date(this.user.account.created), 'YYYY-MM-DD HH:mm (dddd)');
    },
    currentProducts() {
      const now = new Date();
      return this.user.products.current.map((product) => ({
        ...product,
        expired: now > new Date(product.end),
      }));
    },
  },
};
</script>

<style scoped>
.timestamp {
  color: gray;
  margin-left: 0.3rem;
}

.timestamp__danger {
  color: red;
}
</style>
